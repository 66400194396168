window.biskouiSettings = {
  clientId: "929192",
};

(function (d, s) {
  var t = d.getElementsByTagName(s)[0],
    e = d.createElement(s);
  e.async = true;
  e.src = "https://static.biskoui.ch/sdk.js";
  t.parentNode.insertBefore(e, t);
})(document, "script");

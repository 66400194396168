// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/biskoui.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
import Packery from 'packery';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  $('.preview:not(.preview--post)').matchHeight({
    byRow: true,
  });

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  var closeButtonForm = $('.close-form-button');
  var form = $('.popup-form-wrap');
  var openButtonForm = $('.open-form-button');

  openButtonForm.click(function () {
    form.addClass('show');
  });
  closeButtonForm.click(function () {
    form.removeClass('show');
  });

  $('.gallery-slider').each(function () {
    $(this).slick({
      dots: true,
      infinite: true,
      speed: 500,
      fade: true,
      cssEase: 'linear',
      appendDots: $(this).closest('.text-image').find('.gallery-slider__dots'),
      prevArrow: $(this).closest('.text-image').find('.gallery-slider__prev'),
      nextArrow: $(this).closest('.text-image').find('.gallery-slider__next'),
    });
  });

  $('.media-slider').slick({
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: $('.media-slider__prev'),
    nextArrow: $('.media-slider__next'),
    appendDots: $('.media-slider__dots'),
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  $('#play').on('click', function (ev) {
    $(this).css({
      'z-index': '-1',
    });

    $('iframe').css('z-index', '2');
    $('iframe')[0].src += '&autoplay=1';
    ev.preventDefault();
  });

  $('.series__leng-flag').on('click', function () {
    var url = $(this).attr('data-place');
    var block = $(this).parents('.series__leng');
    var items = block.find('.series__leng-link');
    items.each(function () {
      $(this).removeClass('link-active');
      if ($(this).hasClass(url)) {
        $(this).addClass('link-active');
      }
    });
    var flag = block.find('.series__leng-flag');
    flag.each(function () {
      $(this).removeClass('active');
    });
    $(this).addClass('active');
  });

  /**
   * Add fancybox to images
   */
  $('.gallery-item')
    .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
    .attr('rel', 'gallery')
    .attr('data-fancybox', 'gallery');
  $(
    '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  ).fancybox({
    minHeight: 0,
    helpers: {
      overlay: {
        locked: false,
      },
    },
  });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();

  $('.post-slider').slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    rows: 0,
    dots: true,
    prevArrow: $('.post-slider__prev'),
    nextArrow: $('.post-slider__next'),
    appendDots: $('.post-slider__dots'),
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  // post slider section
  $('.single-post-slider').slick({
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    centerPadding: '80px',
    prevArrow: $('.post-slider__prev'),
    nextArrow: $('.post-slider__next'),
    appendDots: $('.post-slider__dots'),
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: true,
        },
      },
    ],
  });

  //recent activities slider
  $('.recent-activities__slider-wrapp').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    prevArrow: $('.recent-activities__prev'),
    nextArrow: $('.activities-list__next'),
    appendDots: $('.recent-activities__dots'),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: true,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: true,
        },
      },
    ],
  });

  // ACF Google Map JS code

  /*
   *  This function will render a Google Map onto the selected jQuery element
   *
   */

  /* global google */

  function render_map($el) {
    // var
    var $markers = $el.find('.marker');
    // var styles = []; // Uncomment for map styling

    // vars
    var args = {
      zoom: 16,
      center: new google.maps.LatLng(0, 0),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      scrollwheel: false,
      // styles : styles // Uncomment for map styling
    };

    // create map
    var map = new google.maps.Map($el[0], args);

    // add a markers reference
    map.markers = [];

    // add markers
    $markers.each(function () {
      add_marker($(this), map);
    });

    // center map
    center_map(map);
  }

  /*
   *  This function will add a marker to the selected Google Map
   */

  var infowindow;

  function add_marker($marker, map) {
    // var
    var latlng = new google.maps.LatLng(
      $marker.attr('data-lat'),
      $marker.attr('data-lng')
    );

    // create marker
    var marker = new google.maps.Marker({
      position: latlng,
      map: map,
      //icon: $marker.data('marker-icon') //uncomment if you use custom marker
    });

    // add to array
    map.markers.push(marker);

    // if marker contains HTML, add it to an infoWindow
    if ($.trim($marker.html())) {
      // create info window
      infowindow = new google.maps.InfoWindow();

      // show info window when marker is clicked
      google.maps.event.addListener(marker, 'click', function () {
        // Close previously opened infowindow, fill with new content and open it
        infowindow.close();
        infowindow.setContent($marker.html());
        infowindow.open(map, marker);
      });
    }
  }

  /*
   *  This function will center the map, showing all markers attached to this map
   */

  function center_map(map) {
    // vars
    var bounds = new google.maps.LatLngBounds();

    // loop through all markers and create bounds
    $.each(map.markers, function (i, marker) {
      var latlng = new google.maps.LatLng(
        marker.position.lat(),
        marker.position.lng()
      );
      bounds.extend(latlng);
    });

    // only 1 marker?
    if (map.markers.length == 1) {
      // set center of map
      map.setCenter(bounds.getCenter());
    } else {
      // fit to bounds
      map.fitBounds(bounds);
    }
  }

  // $('.team-item__text-hover').hover(function () {
  //   $('.team-item__content').toggleClass('hide');
  // });

  /*
   *  This function will render each map when the document is ready (page has loaded)
   */

  $('.acf-map').each(function () {
    render_map($(this));
  });

  $('.team-item__link').each(function () {
    $(this).hover(function () {
      $(this)
        .find('.team-item__text-below, .team-item__text')
        .slideToggle('slow', function () {
          // Animation complete.
        });
    });
  });

  function blogSlider() {
    $('#posts-list').slick({
      rows: 2,
      slidesPerRow: 4,
      dots: true,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 500,
      appendDots: $('.blog-slider__controls').find('.blog-slider__dots'),
      prevArrow: $('.blog-slider__controls').find('.blog-slider__prev'),
      nextArrow: $('.blog-slider__controls').find('.blog-slider__next'),
      responsive: [
        {
          breakpoint: 640,
          settings: {
            slidesPerRow: 1,
            rows: 1,
            variableWidth: true,
          },
        },
        {
          breakpoint: 1150,
          settings: {
            slidesPerRow: 3,
            rows: 3,
          },
        },
        {
          breakpoint: 1600,
          settings: {
            slidesPerRow: 4,
            rows: 3,
          },
        },
      ],
    })
      .on("setPosition", function () {
        resizeSlider();
      });
  }

  $(window).on("resize", function (e) {
    resizeSlider();
  });

  var slickHeight = $(".slick-track").outerHeight();

  function resizeSlider() {
    $(".slick-track")
      .find(".slick-slide .preview")
      .css("height", slickHeight + "px");
  }

  blogSlider();

  function postFilter() {
    var filter = $('#filter');
    $.ajax({
      url: filter.attr('action'),
      data: filter.serialize(), // form data
      type: filter.attr('method'), // POST
      // eslint-disable-next-line no-unused-vars
      beforeSend: function (xhr) { },
      success: function (data) {
        $('#posts-list').slick('unslick');
        $('#posts-list').html(data); // insert data
        blogSlider();
        resizeSlider();
      },
    });
    return false;
  }

  $('.select-category').change(postFilter);

  $('.search-button').click(function (e) {
    e.preventDefault();
    postFilter();
  });

  const $mediaGrid = $('.media-grid');
  if ($mediaGrid.length) {
    $mediaGrid.each((i, el) => {
      new Packery(el, {
        itemSelector: '.grid-item',
        gutter: 40,
      });
    });
  }
  if ( $( '.js-example-basic-hide-search' ).length > 0 ) {
    $('.js-example-basic-hide-search').select2({
      minimumResultsForSearch: Infinity,
    });
  }
});

// Min height Team items

const teamItems = document.querySelectorAll('.team-item__content');

let teamHeight = 84;

function changeStyle() {
  teamItems.forEach(function (teamItem) {
    if (teamItem.offsetHeight > teamHeight) {
      teamHeight = teamItem.offsetHeight;
    }
  });
  teamItems.forEach(function (teamItem) {
    teamItem.style.minHeight = teamHeight + 'px';
  });
}

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});

$(window).on('load resize', function () {
  if ($(window).width() < 640) {
    $('.activities-post__slider-wrapp:not(.slick-initialized)').slick({
      dots: true,
      infinite: true,
      slidesToShow: 1,
      prevArrow: $('.activities-post__prev'),
      nextArrow: $('.activities-post__next'),
      appendDots: $('.activities-post__dots'),
    });
    $('.post-slider-home:not(.slick-initialized)').slick({
      dots: true,
      infinite: true,
      adaptiveHeight: true,
      slidesToShow: 1,
      prevArrow: $('.post-slider__prev'),
      nextArrow: $('.post-slider__next'),
      appendDots: $('.post-slider__dots'),
    });
  } else {
    $('.activities-post__slider-wrapp.slick-initialized').slick('unslick');
    $('.post-slider-home.slick-initialized').slick('unslick');
  }

  changeStyle();
});
